import "./css/index.css"
import Terminal from './js/Terminal';

export default function App() {
    return (
        <div>
            
            <Terminal />
        </div>
    );
}